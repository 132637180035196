const config = {
    apiUrl: "https://api.uat.windgis.io/",
    viewerUrl: "https://viewer.uat.windgis.io/",
    coreApiUrl: "https://core-api.demo.lautec.com/v1/",
    dprApiUrl: "https://lautec-dpr-api-demo.azurewebsites.net/",
    coreAppUrl: "https://lauteccorewebdemo.z6.web.core.windows.net/",
    landingUrl: "https://landingpage.uat.windgis.io",
    basemap: "mapbox://styles/lautec/ck949aof41kc41itbk257vk78",
    mapboxApiKey: "pk.eyJ1IjoibGF1dGVjIiwiYSI6ImNrNzRpNjFxaTBpc2IzZnBkNHU3MGJoMmoifQ.yNFawhQiPQGVujuEwb-TIg",
    moduleId: "KyMbCzYaedY-",
    cryptoUtilsPass: "Op7MA2gaGsO",
    azureB2C: {
        auth: {
            clientId: "abda2fc4-9a78-4f81-851c-66753ca296be", // This is the ONLY mandatory field that you need to supply.
            authority: "https://identity.demo.lautec.com/lautecb2cdemo.onmicrosoft.com/b2c_1a_signin", // Defaults to "https://login.microsoftonline.com/common"
            passwordResetAuthority: "https://identity.demo.lautec.com/lautecb2cdemo.onmicrosoft.com/b2c_1a_passwordreset",
            knownAuthorities: ["identity.demo.lautec.com"], // Mark your B2C tenant's domain as trusted.
            redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
            postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        },
        system: {
            allowRedirectInIframe: true
        }
    },
    loginRequest: {
        scopes: ["https://lautecb2cdemo.onmicrosoft.com/apis/default"]
    },
    forgetPasswordErrorCode: "AADB2C90118",
    coreModule: {
        id: "Core",
        name: "Core",
        description: "Account Management",
        status: 1,
        locationUrl: "https://lauteccorewebdemo.z6.web.core.windows.net/",
        favIconUrl: "https://lautecdemo.blob.core.windows.net/externals/favicon-core-module.svg",
        landingPageUrl: "https://lauteccorewebdemo.z6.web.core.windows.net/"
    },
    analyticsModuleId: "EZngFMeYkco-"
};

export default config;
